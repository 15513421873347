import { Config } from "./Config";

const url = Config.api.local;

export const HttpConsultas = {
    ObtenerEspacio: async (data) => {
        try {
            // Aquí no agregamos los encabezados manualmente para "multipart/form-data"
            let consultaObtenerEspacio = await fetch(`${url}obtenerEspacio?id=${data}`, {
                method: "GET",
            });
            let resconsultaObtenerEspacio = await consultaObtenerEspacio.json();
            let consultaFinal = {
                status: consultaObtenerEspacio.status,
                data: resconsultaObtenerEspacio,
            };
            return consultaFinal;
        } catch (err) {
            return { error: "Error de servidor" };
        }
    },
    RegistrarReservacion: async (data) => {
        try {
          const headers = new Headers();
          headers.append("Content-Type", "application/json");
          let consultaregistrarReservacion = await fetch(`${url}registrarReservacion`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
          });
          let resconsultaregistrarReservacion = await consultaregistrarReservacion.json();
          let consultaFinal = {
            status: consultaregistrarReservacion.status,
            data: resconsultaregistrarReservacion,
          };
          return consultaFinal;
        } catch (err) {
          return { error: "Error de servidor" };
        }
    },
    ObtenerEspacios: async () => {
      try {
          // Aquí no agregamos los encabezados manualmente para "multipart/form-data"
          let consultaObtenerEspacios = await fetch(`${url}obtenerEspacios`, {
              method: "GET",
          });
          let resconsultaObtenerEspacios = await consultaObtenerEspacios.json();
          let consultaFinal = {
              status: consultaObtenerEspacios.status,
              data: resconsultaObtenerEspacios,
          };
          return consultaFinal;
      } catch (err) {
          return { error: "Error de servidor" };
      }
  },
};
