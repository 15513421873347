import React, { useEffect, useState } from "react";

export default function Filter({ data, setDataFinal }) {
  const [checkedCity, setCheckedCity] = useState(""); // Solo una ciudad seleccionada
  const [ciudadesDisponibles, setCiudadesDisponibles] = useState([]);

  useEffect(() => {
    if (data) {
      // Obtener las ciudades únicas de los datos disponibles y contar la cantidad de alquileres por ciudad
      const ciudadesMap = {};
      data.forEach((item) => {
        if (ciudadesMap[item.ciudad]) {
          ciudadesMap[item.ciudad] += 1;
        } else {
          ciudadesMap[item.ciudad] = 1;
        }
      });
      setCiudadesDisponibles(ciudadesMap);
    }
  }, [data]);

  const handleCityCheckboxChange = (city) => {
    // Si la ciudad ya está seleccionada, deseleccionarla; de lo contrario, seleccionar la nueva ciudad
    const newCheckedCity = city === checkedCity ? "" : city;

    setCheckedCity(newCheckedCity);

    // Filtrar los datos de acuerdo a la ciudad seleccionada
    if (newCheckedCity) {
      const filteredData = data.filter((item) => item.ciudad === newCheckedCity);
      setDataFinal(filteredData);
    } else {
      // Si no hay ninguna ciudad seleccionada, se restablece toda la data
      setDataFinal(data);
    }

    // Mostrar el valor del checkbox seleccionado en la consola
    console.log(`Ciudad seleccionada: ${newCheckedCity}`);
  };

  const applyResetFilter = () => {
    // Restablecer todos los filtros a su estado inicial
    setCheckedCity("");
    setDataFinal(data);
  };

  return (
    <div className="col-span-12 lg:col-span-3">
      <div className="p-4 rounded-md shadow dark:shadow-gray-700 sticky top-20">
        <div className="mt-6">
          <h5 className="text-lg font-medium">Ciudades</h5>
          <div className="mt-3">
            {Object.entries(ciudadesDisponibles).map(([city, count]) => (
              <div className="flex items-center mb-0" key={city}>
                <input
                  className="form-radio rounded border-gray-100 dark:border-gray-800 text-red-500 focus:border-red-300 focus:ring focus:ring-offset-0 focus:ring-red-500/20 focus:ring-opacity-50 me-2"
                  type="radio" // Cambié a "radio" para permitir solo una selección
                  value={city}
                  id={city}
                  checked={checkedCity === city}
                  onChange={() => handleCityCheckboxChange(city)}
                />
                <label
                  className="form-checkbox-label text-slate-400"
                  htmlFor={city}
                >
                  {city} ({count})
                </label>
              </div>
            ))}
          </div>
        </div>

        {/* Mostrar el botón "Limpiar Filtros" solo si hay una ciudad seleccionada */}
        {checkedCity && (
          <div className="flex flex-col">
            <button
              onClick={applyResetFilter}
              className="mt-4 p-2 bg-red-500 text-white rounded"
            >
              Limpiar Filtros
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
